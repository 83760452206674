<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['Green Solutions.']" />
        </div>
      </div>
    </div>
    <div class="section">
      <!--<div class="separator-line separator-primary" />-->
      <div class="projects-5">
        <div class="row">
          <div class="col-md-5 ml-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u18.jpg')"
            >
              <h2 class="card-title">
                <!--Research & Baseline Studies-->
              </h2>
            </card>
            <div class="row">
              <h2 class="card-title">
                Research & Baseline Studies
              </h2>
            </div>
          </div>
          <div class="col-md-6 mr-auto">
            <info-section
              type="danger"
              title=" "
              description="Baseline studies are required to establish an environmental benchmark
                        against which future change may be measured. These studies often
                        involve measurement of physical, chemical and biological parameters.
                        Additionally socio-economic investigations are also undertaken in order
                        to assess any potential adverse or beneficial impacts on the affected
                        communities."
            />

            <info-section
              type="danger"
              title=" "
              description="We specialize and undertake all these assessments in order to facilitate
                        project and management planning for receptor local communities and
                        project implementation. This process entails transects walks, interviews,
                        surveys, specimen sampling and production of Habitat maps as useful
                        tools and techniques that facilitate the planning."
            />

            <info-section
              type="danger"
              title=" "
              description="All these with which to assist planning for a development and are
                        essential for resource management purposes (e.g. protected areas).
                        These are all conducted according to international and regional
                        standards in conjunction with specimen testing undertaken at certified
                        and accredited laboratories with the necessary QA and QC measures in
                        place that ensure dependable results."
            />
            <info-section
              type="danger"
              title=" "
              description="We also organize, design and conduct a number of applied long-term
                        research programs relating to Environmental (Impact) Assessment
                        Sanitation and Waste."
            />
          </div>
        </div>
        <hr>
        
        
        <div class="row">
          <div class="col-md-5 ktm2 mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u15.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Environmental Auditing & Monitoring
              </h2>
            </div>
          </div>
          <div class="col-md-6 ml-auto">
            <info-section
              type="warning"
              title=" "
              description="Our services for environmental monitoring includes data collection,
                        analysis and interpretation, and often covers a diversity of parameters
                        and natural assets including air quality, noise, groundwater quality and
                        quantity, surface water quality, biological diversity and habitat health.
                        This is undertaken in order to effectively understand the natural daily,
                        seasonal and yearly changes in background and active levels of all
                        measured parameters in order to explain the cause of any changes and
                        attribute them either to natural fluctuations or to disturbance from the
                        proposed or current development."
            />

            <info-section
              type="warning"
              title=" "
              description="All the monitoring is undertaken according to an approved
                        Environmental Management Plan (EMP) by ZEMA, which provides the
                        basis, justification, the parameters to be monitored and duration
                        during the project life."
            />

            <info-section
              type="warning"
              title=" "
              description="Often times, this follows an approved ESIS or EPB report or may be
                        prepared separately for projects or undertakings that are already in
                        operation."
            />
          </div>
          <div class="col-md-5 ktm mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u15.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Environmental Auditing & Monitoring
              </h2>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-5 ml-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u11.jpeg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Public Stakeholder Consultation
              </h2>
            </div>
          </div>
          <div class="col-md-6 mr-auto">
            <info-section
              type="danger"
              title=" "
              description="The EMA No. 12 of 2011 as read together with the EIA
                        Regulations No.28 of 1997 make it mandatory for the
                        active engagement of the potential Interested and
                        Affected Persons (IAPs), stakeholders, and the general
                        public, in the environmental process assessment and
                        decision making."
            />

            <info-section
              type="danger"
              title=" "
              description="This is done to afford all an opportunity
                        to register their concerns concerning a proposed or on-going project so as to facilitate
                        inclusive and equitable
                        Decisions from the Agency. This active engagement is
                        done through formal and informal invitation and holding
                        of public consultative meetings."
            />

            <info-section
              type="danger"
              title=" "
              description="SEUS Consult manages such engagements either as part of
                        the Project Scoping for potential impacts or as facilitators
                        for Public Hearings on behalf of ZEMA, for proposed
                        projects that have undertaken the ESIA, reported to ZEMA
                        and earmarked for consideration for final Decision."
            />
            <info-section
              type="danger"
              title=" "
              description=" These meetings are held throughout the country in local
                        communities or stakeholder venues and in varying
                        circumstances and situations, after which the related
                        reporting is made."
            />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-5 ktm2 mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u20.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Capacity Building and Training
              </h2>
            </div>
          </div>
          <div class="col-md-6 ml-auto">
            <info-section
              type="warning"
              title=" "
              description="As part of its commitment, SEUS Consult provides manpower complement and professional capacity
                        building
                        to it’s valued clients. This is offered as part of its project management services. These
                        services enhances the
                        clients’ capacity to implement or manage their related projects and enriches the synergy between
                        the firm
                        and the client, which results into positive outcomes."
            />

            <info-section
              type="warning"
              title=" "
              description="These services include, Strategic Planning & Situational Analysis, Policy Development &
                        Organizational
                        Structure Review, Project Supervision, Monitoring and Evaluation. The others are Needs
                        Assessment,
                        Governance and HIV /AIDS training for the workplace. Specialized apprenticeship is offered to
                        several interns
                        pursuing their graduate and postgraduate programmes from their different disciplines."
            />
          </div>
          <div class="col-md-5 ktm mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u20.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Capacity Building and Training
              </h2>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-5 ml-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u9.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Water, Sanitation and Natural Resources Management
              </h2>
            </div>
          </div>
          <div class="col-md-6 mr-auto">
            <info-section
              type="danger"
              title=" "
              description="More than one billion people around the world do not have access to clean water and twice as many
                        have
                        no sanitation. SEUS Consult has invested in capacity to develop and implement appropriate,
                        affordable, and
                        innovative water supply, sanitation and waste management systems, which recognize the role of
                        local and
                        national government and community management in the services provision."
            />

            <info-section
              type="danger"
              title=" "
              description=" We promote and provide technical expertise in sanitation solutions and small scale community
                        water
                        systems for peri urban and rural areas."
            />

            <info-section
              type="danger"
              title=" "
              description="SEUS Consult is one of the leading waste and resources management consultancies providing a
                        one-stop
                        shop services. The need to meet statutory targets for waste reduction, recycling and recovery
                        further
                        strengthens the case for closing the loop on resource use."
            />
            <info-section
              type="danger"
              title=" "
              description="Closing the loop for a particular product life cycle creates opportunities for municipal
                        authorities, SME’s,
                        CBEs and the society in general as it provides an avenue for the efficient use of raw materials
                        and energy to
                        recycling and secondary markets for recovered resources."
            />
            <info-section
              type="danger"
              title=" "
              description="We have specializations and experience in the following:"
            />
            <ul class="green-eco">
              <li>
                Developing of Municipal Integrated Solid Waste Management (SWM) solutions and Strategic
                plans.
              </li>
              <li>
                Cost effective Waste collection system designs and fleet management.
              </li>
              <li>
                Waste Assessment: Characterization, composition analysis and forecasting
              </li>
              <li>
                Application of waste recovery technologies that utilize any residual value in waste
              </li>
              <li>
                Development of Payment systems for waste collection and Performance Indicators for the CBEs
                involved in waste collection
              </li>
              <li>
                Capacity building and Strategic planning services to Municipal authorities and Community
                Based
                Enterprises (CBE’s) in SWM
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-5 ktm2 mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u24.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Environmental & Social Impact Assessment (ESIA)
              </h2>
            </div>
          </div>
          <div class="col-md-6 ml-auto">
            <info-section
              type="warning"
              title=" "
              description="SEUS Consult works in close collaboration with the
                        ZEMA, (the Zambia Environmental Management
                        Agency) - whose national statutory core mandate is
                        to manage and regulate all aspects of the
                        environment for a sustainable future."
            />

            <info-section
              type="warning"
              title=" "
              description="SEUS Consult provides its quality services within
                        the framework of the environmental management
                        laws, which are the Environmental Management
                        Act No. 12 of 2011 together with the Environmental
                        Impact Assessment Regulations No.28 of 1997."
            />

            <info-section
              type="warning"
              title=" "
              description="These provide for an Integrated Environmental
                        Management through protection and conservation
                        of the environment and the Sustainable
                        management and use of natural resources,
                        Environmental Monitoring during the construction
                        and/ or implementation phase of a project and
                        Rights of the public and stakeholders to Decisions
                        affecting the environment.

                The projects that the firm has been principal or part
                        of the consulting team includes:"
            />
            <ul class="green-eco">
              <li>
                Establishment of Cement and Lime processing
                plants and the associated Quarries.
              </li>
              <li>
                Thermal and mini Hydro power stations and
                HV power transmission lines.
              </li>
              <li>
                Techno-economic and Feasibility Studies and
                Detailed Engineering Designs & Tender
                Document preparation for Roads construction,
                and the establishment of Asphalt plants.
              </li>
              <li>
                Multi-use complexes for Housing, Office and
                Malls developments.
              </li>
              <li>
                Large scale Integrated Agricultural schemes &
                associated facilities.
              </li>
            </ul>
          </div>
          <div class="col-md-5 ktm mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/img/u24.jpg')"
            >
              <h2 class="card-title" />
            </card>
            <div class="row">
              <h2 class="card-title">
                Environmental & Social Impact Assessment (ESIA)
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, InfoSection, } from '@/components';
  import TypingTitle from '@/components/TypingTitle';
 

  export default {
    name: 'GreenSolution',
    bodyClass: 'eco',
    components: {
      Card,
      InfoSection,
      TypingTitle
    },

  }
</script>
<style>
</style>
